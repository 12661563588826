import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '', //메인 (빈화면)
    loadChildren: () => import('./pages/waiting/waiting.module').then(m => m.WaitingPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'main', //메인 (셀럽, 회원, 비회원 공통)
  //   loadChildren: () => import('./pages/main-tabs/main-tabs.module').then(m => m.MainTabsPageModule),
  // },
  {
    path: 'dashboard', //앱 설치화면
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
  },
  {
    path: 'user-tabs', //회원:: 메인 탭
    loadChildren: () => import('./pages/user/user-tabs/user-tabs.module').then(m => m.UserTabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login', //로그인
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'login/kakao', //웹 카카오 로그인 리턴 페이지
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'login/naver', //웹 네이버 로그인 리턴 페이지
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'login/apple', //웹 애플 로그인 리턴 페이지
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'login-email', //로그인 이메일
    loadChildren: () => import('./pages/account/login-email/login-email.module').then(m => m.LoginEmailPageModule),
  },
  {
    path: 'infor-add', //추가정보 입력
    loadChildren: () => import('./pages/account/infor-add/infor-add.module').then(m => m.InforAddPageModule),
  },
  {
    path: 'recommendcode', //추천코드 입력
    loadChildren: () => import('./pages/account/recommendcode/recommendcode.module').then(m => m.RecommendcodePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sign-up-terms', //이메일 가입시 약관동의
    loadChildren: () => import('./pages/account/sign-up-terms/sign-up-terms.module').then(m => m.SignUpTermsPageModule),
  },
  {
    path: 'sign-up-inner', //이메일 가입시 정보입력
    loadChildren: () => import('./pages/account/sign-up-inner/sign-up-inner.module').then(m => m.SignUpInnerPageModule),
  },
  {
    path: 'sign-up-social', //이메일 가입시 닉네임 입력
    loadChildren: () => import('./pages/account/sign-up-social/sign-up-social.module').then(m => m.SignUpSocialPageModule),
  },
  {
    path: 'password-find', //비밀번호 찾기
    loadChildren: () => import('./pages/account/password-find/password-find.module').then(m => m.PasswordFindPageModule),
  },
  {
    path: 'password-result', //비밀번호 찾기 결과
    loadChildren: () => import('./pages/account/password-result/password-result.module').then(m => m.PasswordResultPageModule),
  },
  {
    path: 'profile-detail', //회원:: 모달:: 셀럽 상세
    loadChildren: () => import('./pages/user/profile-detail/profile-detail.module').then(m => m.ProfileDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'friendtalk-ask', //셀럽에게 채팅 신청
    loadChildren: () => import('./pages/user/friendtalk-ask/friendtalk-ask.module').then(m => m.FriendtalkAskPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'givegift-d', //회원:: 셀럽에게 하트 선물
    loadChildren: () => import('./pages/user/givegift-d/givegift-d.module').then(m => m.GivegiftDPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-detail-my', //회원 :: 내 프로필
    loadChildren: () => import('./pages/user/profile-detail-my/profile-detail-my.module').then(m => m.ProfileDetailMyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'givegift', //회원:: 하트 선물하기 (셀럽 선택가능)
    loadChildren: () => import('./pages/user/givegift/givegift.module').then(m => m.GivegiftPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-edit', //회원:: 프로필 수정
    loadChildren: () => import('./pages/user/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'friendmanage', //메인에서 셀럽관리 설정
    loadChildren: () => import('./pages/user/friendmanage/friendmanage.module').then(m => m.FriendmanagePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setting', //설정
    loadChildren: () => import('./pages/user/setting/setting.module').then(m => m.SettingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'personal-setting', //회원:: 개인정보 설정
    loadChildren: () => import('./pages/user/personal-setting/personal-setting.module').then(m => m.PersonalSettingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'password-edit', //비밀번호 변경
    loadChildren: () => import('./pages/user/password-edit/password-edit.module').then(m => m.PasswordEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notice', //공지사항
    loadChildren: () => import('./pages/user/notice/notice.module').then(m => m.NoticePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inquire', //문의하기 목록
    loadChildren: () => import('./pages/user/inquire/inquire.module').then(m => m.InquirePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inquire-detail', //문의하기 상세
    loadChildren: () => import('./pages/user/inquire-detail/inquire-detail.module').then(m => m.InquireDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inquire-write', //문의하기 글쓰기
    loadChildren: () => import('./pages/user/inquire-write/inquire-write.module').then(m => m.InquireWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'service', //이용약관
    loadChildren: () => import('./pages/user/terms/service/service.module').then(m => m.ServicePageModule),
  },
  {
    path: 'personal-info', //개인정보 취급방침
    loadChildren: () => import('./pages/user/terms/personal-info/personal-info.module').then(m => m.PersonalInfoPageModule),
  },
  {
    path: 'search', //셀럽 검색
    loadChildren: () => import('./pages/user/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recomfriend', //추천셀럽
    loadChildren: () => import('./pages/user/recomfriend/recomfriend.module').then(m => m.RecomfriendPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'friend-category', //카테고리별 셀럽
    loadChildren: () => import('./pages/user/friend-category/friend-category.module').then(m => m.FriendCategoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-detail', //회원:: 채팅 상세
    loadChildren: () => import('./pages/user/message-detail/message-detail.module').then(m => m.MessageDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-edit', //회원:: 채팅별 설정
    loadChildren: () => import('./pages/user/message-edit/message-edit.module').then(m => m.MessageEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mediagathering', //회원:: 채팅별 사진/영상 모아보기 (현재 모든 미디어 다보임)
    loadChildren: () => import('./pages/user/mediagathering/mediagathering.module').then(m => m.MediagatheringPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'photovideo-detail', //사진, 영상 상세
    loadChildren: () => import('./pages/user/photovideo-detail/photovideo-detail.module').then(m => m.PhotovideoDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar', //채팅 캘린더
    loadChildren: () => import('./pages/user/calendar/calendar.module').then(m => m.CalendarPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'schedule-write', //채팅 캘린더 쓰기
    loadChildren: () => import('./pages/user/schedule-write/schedule-write.module').then(m => m.ScheduleWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-write', //회원:: 영상통화 신청
    loadChildren: () => import('./pages/user/videocall-write/videocall-write.module').then(m => m.VideocallWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'businessnotice', //회원:: 모달:: 섭외시 기업용 약관 상세
    loadChildren: () => import('./pages/user/businessnotice/businessnotice.module').then(m => m.BusinessnoticePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reservationtime', //회원:: 모달::  영상통화 신청시 시간 설정
    loadChildren: () => import('./pages/user/reservationtime/reservationtime.module').then(m => m.ReservationtimePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videomessage-write', //회원:: 영상메시지 신청
    loadChildren: () => import('./pages/user/videomessage-write/videomessage-write.module').then(m => m.VideomessageWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'absencecall', //회원:: 모달:: 영상통화시 부재중 예약시간 추가설정 (안씀)
    loadChildren: () => import('./pages/user/absencecall/absencecall.module').then(m => m.AbsencecallPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'store', //회원:: 모달:: 하트 충전
    loadChildren: () => import('./pages/user/store/store.module').then(m => m.StorePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-report',  //모달 :: 팬캠 신고하기
    loadChildren: () => import('./pages/user/square-report/square-report.module').then(m => m.SquareReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-liked', //팬캠 우상단 클릭 팬캠 즐겨찾기
    loadChildren: () => import('./pages/user/square-liked/square-liked.module').then(m => m.SquareLikedPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-detail', //팬캠 상세
    loadChildren: () => import('./pages/user/square-detail/square-detail.module').then(m => m.SquareDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-friend', //회원:: 팬캠 우상단 클릭 (내 셀럽의 새로운 메시지)
    loadChildren: () => import('./pages/user/square-friend/square-friend.module').then(m => m.SquareFriendPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-write', //회원:: 팬캠 글쓰기
    loadChildren: () => import('./pages/user/square-write/square-write.module').then(m => m.SquareWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'alarm', //회원:: 알람
    loadChildren: () => import('./pages/user/alarm/alarm.module').then(m => m.AlarmPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event', //더보기 이벤트
    loadChildren: () => import('./pages/user/event/event.module').then(m => m.EventPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord', //회원:: 모달:: 다이렉트메시지 신청현황 상세
    loadChildren: () => import('./pages/user/dealrecord/dealrecord.module').then(m => m.DealrecordPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'storage', //회원:: 보관함
    loadChildren: () => import('./pages/user/storage/storage.module').then(m => m.StoragePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'private-detail', //회원:: 보관함->다이렉트메시지 탭->상세
    loadChildren: () => import('./pages/user/private-detail/private-detail.module').then(m => m.PrivateDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall', //영상통화 셀럽:걸기, 회원:받기
    loadChildren: () => import('./pages/user/videocall/videocall.module').then(m => m.VideocallPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-apply', //회원가입시 셀럽신청 (안씀)
    loadChildren: () => import('./pages/celebrity/cel-apply/cel-apply.module').then(m => m.CelApplyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'com-apply', //기업 신청 및 수정
    loadChildren: () => import('./pages/celebrity/com-apply/com-apply.module').then(m => m.ComApplyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-cel-detail', //셀럽:: 모달:: 프로필 상세
    loadChildren: () => import('./pages/celebrity/profile-cel-detail/profile-cel-detail.module').then(m => m.ProfileCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-cel-detail-my', //셀럽:: 모달:: 프로필 나
    loadChildren: () => import('./pages/celebrity/profile-cel-detail-my/profile-cel-detail-my.module').then(m => m.ProfileCelDetailMyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-cel-edit', //셀럽:: 프로필 수정
    loadChildren: () => import('./pages/celebrity/profile-cel-edit/profile-cel-edit.module').then(m => m.ProfileCelEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'personal-cel-setting', //셀럽:: 개인정보 수정
    loadChildren: () => import('./pages/celebrity/personal-cel-setting/personal-cel-setting.module').then(m => m.PersonalCelSettingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-cel-list', //셀럽:: 채팅 목록
    loadChildren: () => import('./pages/celebrity/message-cel-list/message-cel-list.module').then(m => m.MessageCelListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-cel-detail', //셀럽:: 채팅 상세
    loadChildren: () => import('./pages/celebrity/message-cel-detail/message-cel-detail.module').then(m => m.MessageCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-write', //셀럽:: 모달:: 채팅 글쓰기 
    loadChildren: () => import('./pages/celebrity/message-write/message-write.module').then(m => m.MessageWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-check', //셀럽:: 모달:: 채팅 최종확인 및 보내기
    loadChildren: () => import('./pages/celebrity/message-check/message-check.module').then(m => m.MessageCheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-get', //셀럽:: 모달:: 회원의 채팅 확인
    loadChildren: () => import('./pages/celebrity/message-get/message-get.module').then(m => m.MessageGetPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-cel-edit', //셀럽:: 채팅방별 설정
    loadChildren: () => import('./pages/celebrity/message-cel-edit/message-cel-edit.module').then(m => m.MessageCelEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mediagathering-cel', //셀럽:: 채팅 미디어 모아보기
    loadChildren: () => import('./pages/celebrity/mediagathering-cel/mediagathering-cel.module').then(m => m.MediagatheringCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rightfee-setup', //셀럽:: 이용권한 및 금액설정
    loadChildren: () => import('./pages/celebrity/rightfee-setup/rightfee-setup.module').then(m => m.RightfeeSetupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rightfee-help', //셀럽:: 모달:: 금액설정 도움말 
    loadChildren: () => import('./pages/celebrity/rightfee-help/rightfee-help.module').then(m => m.RightfeeHelpPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calltime-setup', //셀럽:: 통화가능시간 설정
    loadChildren: () => import('./pages/celebrity/calltime-setup/calltime-setup.module').then(m => m.CalltimeSetupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'storage-cel', //셀럽:: 보관함
    loadChildren: () => import('./pages/celebrity/storage-cel/storage-cel.module').then(m => m.StorageCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-cel-setting-requestmsg', //셀럽:: 자동회신 메시지 설정
    loadChildren: () => import('./pages/celebrity/message-cel-setting-requestmsg/message-cel-setting-requestmsg.module').then(m => m.MessageCelSettingRequestmsgPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-cel-setting-callbg', //셀럽:: 채팅방별 환경 설정
    loadChildren: () => import('./pages/celebrity/message-cel-setting-callbg/message-cel-setting-callbg.module').then(m => m.MessageCelSettingCallbgPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videomessage-cel', //셀럽:: 영상메시지 신청 목록
    loadChildren: () => import('./pages/celebrity/videomessage-cel/videomessage-cel.module').then(m => m.VideomessageCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videomessage-cel-detail', //셀럽:: 영상메시지 신청 상세
    loadChildren: () => import('./pages/celebrity/videomessage-cel-detail/videomessage-cel-detail.module').then(m => m.VideomessageCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'alarm-cel', //셀럽:: 알람 목록
    loadChildren: () => import('./pages/celebrity/alarm-cel/alarm-cel.module').then(m => m.AlarmCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event-cel', //셀럽:: 이벤트
    loadChildren: () => import('./pages/celebrity/event-cel/event-cel.module').then(m => m.EventCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-cel', //셀럽:: 모달:: 캐스팅 상세
    loadChildren: () => import('./pages/celebrity/dealrecord-cel/dealrecord-cel.module').then(m => m.DealrecordCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'withdrawal-ask', //셀럽:: 모달:: 출금신청
    loadChildren: () => import('./pages/celebrity/withdrawal-ask/withdrawal-ask.module').then(m => m.WithdrawalAskPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'storage-cel-list', //셀럽:: 더보기 > 보관함
    loadChildren: () => import('./pages/celebrity/storage-cel-list/storage-cel-list.module').then(m => m.StorageCelListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mediastorage-cel', //셀럽:: 사진,영상 모아보기
    loadChildren: () => import('./pages/celebrity/mediastorage-cel/mediastorage-cel.module').then(m => m.MediastorageCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'basic-color', //채팅방 배경 색상 설정
    loadChildren: () => import('./pages/user/basic-color/basic-color.module').then(m => m.BasicColorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'voicemessage-write', //회원:: 음성메시지 신청
    loadChildren: () => import('./pages/user/voicemessage-write/voicemessage-write.module').then(m => m.VoicemessageWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-detail', //회원:: 모달:: 다이렉트메시지 상세내역
    loadChildren: () => import('./pages/user/dealrecord-detail/dealrecord-detail.module').then(m => m.DealrecordDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-check', //회원:: 모달:: 다이렉트메시지 신청전 확인
    loadChildren: () => import('./pages/user/dealrecord-check/dealrecord-check.module').then(m => m.DealrecordCheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-cancel-detail', //회원:: 모달:: 다이렉트메시지 취소 내역
    loadChildren: () => import('./pages/user/dealrecord-cancel-detail/dealrecord-cancel-detail.module').then(m => m.DealrecordCancelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'withdrawal-help', //셀럽:: 모달:: 출금가능 도움말
    loadChildren: () => import('./pages/celebrity/withdrawal-help/withdrawal-help.module').then(m => m.WithdrawalHelpPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'category-set', //셀럽:: 모달:: 카테고리 선택
    loadChildren: () => import('./pages/celebrity/category-set/category-set.module').then(m => m.CategorySetPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-tabs', //셀럽:: 메인 탭
    loadChildren: () => import('./pages/celebrity/cel-tabs/cel-tabs.module').then(m => m.CelTabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-cel', //셀럽:: 영상통화 신청 목록
    loadChildren: () => import('./pages/celebrity/videocall-cel/videocall-cel.module').then(m => m.VideocallCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'voicemessage-cel', //셀럽:: 음성메시지 신청 목록
    loadChildren: () => import('./pages/celebrity/voicemessage-cel/voicemessage-cel.module').then(m => m.VoicemessageCelPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-cel-detail', //셀럽:: 모달:: 영상메시지 신청 상세
    loadChildren: () => import('./pages/celebrity/videocall-cel-detail/videocall-cel-detail.module').then(m => m.VideocallCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videomessage-cel-detail-check', //셀럽:: 모달:: 영상메시지 전송전 확인
    loadChildren: () => import('./pages/celebrity/videomessage-cel-detail-check/videomessage-cel-detail-check.module').then(m => m.VideomessageCelDetailCheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'voicemessage-cel-detail', //셀럽:: 모달:: 음성메시지 신청 상세
    loadChildren: () => import('./pages/celebrity/voicemessage-cel-detail/voicemessage-cel-detail.module').then(m => m.VoicemessageCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'voicemessage-cel-detail-check', //셀럽:: 모달:: 음성메시지 전송전 확인
    loadChildren: () => import('./pages/celebrity/voicemessage-cel-detail-check/voicemessage-cel-detail-check.module').then(m => m.VoicemessageCelDetailCheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-cel-sales-detail', //셀럽:: 모달:: 더보기 > 거래내역 > 판매상세 (안씀)
    loadChildren: () => import('./pages/celebrity/dealrecord-cel-sales-detail/dealrecord-cel-sales-detail.module').then(m => m.DealrecordCelSalesDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-cel-cancel-detail', //셀럽:: 모달:: 더보기 > 거래내역 > 취소상세 (안씀)
    loadChildren: () =>
      import('./pages/celebrity/dealrecord-cel-cancel-detail/dealrecord-cel-cancel-detail.module').then(m => m.DealrecordCelCancelDetailPageModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'square-cel-liked', //셀럽:: 팬캠 즐겨찾기
    loadChildren: () => import('./pages/celebrity/square-cel-liked/square-cel-liked.module').then(m => m.SquareCelLikedPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'waiting', //빈화면
    loadChildren: () => import('./pages/waiting/waiting.module').then(m => m.WaitingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealcancel-add-friend', //셀럽:: 모달:: 채팅추가 회원 취소 상세
    loadChildren: () => import('./pages/user/dealcancel-add-friend/dealcancel-add-friend.module').then(m => m.DealcancelAddFriendPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'audio-list', //셀럽:: 음성메시지 전송시 나의 음성 목록 (오류)
    loadChildren: () => import('./pages/celebrity/audio-list/audio-list.module').then(m => m.AudioListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat-content-detail', //채팅안에서 영상,음성,사진 상세
    loadChildren: () => import('./pages/chat-content-detail/chat-content-detail.module').then(m => m.ChatContentDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'photovideo-storage-detail', //회원:: 보관함 이미지,영상,음성 상세
    loadChildren: () => import('./pages/user/photovideo-storage-detail/photovideo-storage-detail.module').then(m => m.PhotovideoStorageDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'waiting-approval', //회원가입 인증전 화면
    loadChildren: () => import('./pages/waiting-approval/waiting-approval.module').then(m => m.WaitingApprovalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'square-inquiry-write', //신고된 팬캠 이의 신청
    loadChildren: () => import('./pages/square-inquiry-write/square-inquiry-write.module').then(m => m.SquareInquiryWritePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'business-term', //비즈니스 약관
    loadChildren: () => import('./pages/user/terms/business-term/business-term.module').then(m => m.BusinessTermPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'image-detail', //사진 상세보기 (문의글쓰기, 프로필)
    loadChildren: () => import('./pages/image-detail/image-detail.module').then(m => m.ImageDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'message-write-confirm', //셀럽:: 모달:: 채팅보내기 최종확인 페이지에서 영상, 음성 컨텐츠 클릭시 확인
    loadChildren: () => import('./pages/celebrity/message-write-confirm/message-write-confirm.module').then(m => m.MessageWriteConfirmPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-detail', //영상통화중 페이지
    loadChildren: () => import('./pages/videocall-detail/videocall-detail.module').then(m => m.VideocallDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-end', //영상통화 종료
    loadChildren: () => import('./pages/videocall-end/videocall-end.module').then(m => m.VideocallEndPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-detail-list', //프로필 이미지,영상 상세목록 (스와이프)
    loadChildren: () => import('./pages/profile-detail-list/profile-detail-list.module').then(m => m.ProfileDetailListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'photovideo-detail-storage', //셀럽:: 보관함 컨텐츠 상세
    loadChildren: () => import('./pages/celebrity/photovideo-detail-storage/photovideo-detail-storage.module').then(m => m.PhotovideoDetailStoragePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'faq', //FAQ
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting', //회원:: 셀럽 캐스팅하기전 셀럽정보 확인
    loadChildren: () => import('./pages/user/casting/casting.module').then(m => m.CastingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting-register', //회원,비회원:: 셀럽 캐스팅하기 화면
    loadChildren: () => import('./pages/casting-register/casting-register.module').then(m => m.CastingRegisterPageModule),
  },
  {
    path: 'chatgpt', //회원,비회원:: 셀럽 캐스팅하기 화면
    loadChildren: () => import('./pages/chatgpt/chatgpt.module').then(m => m.ChatgptPageModule),
  },
  {
    path: 'casting-cel-detail', //회원,비회원:: 셀럽 상세
    loadChildren: () => import('./pages/casting-cel-detail/casting-cel-detail.module').then(m => m.CastingCelDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting-cel-profile', //회원,비회원:: 셀럽 프로파일
    loadChildren: () => import('./pages/casting-cel-profile/casting-cel-profile.module').then(m => m.CastingCelProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'occasion', //회원:: 셀럽 섭외 신청
    loadChildren: () => import('./pages/user/occasion/occasion.module').then(m => m.OccasionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'appointment-time', //회원:: 모달:: 셀럽 섭외 신청 시간 설정
    loadChildren: () => import('./pages/appointment-time/appointment-time.module').then(m => m.AppointmentTimePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'position-map-modal', //회원:: 모달:: 셀럽 섭외 신청 지역 설정
    loadChildren: () => import('./pages/position-map-modal/position-map-modal.module').then(m => m.PositionMapModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cost-modal', //회원:: 모달:: 셀럽 섭외 신청 가격 설정
    loadChildren: () => import('./pages/cost-modal/cost-modal.module').then(m => m.CostModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event-list', //셀럽 섭외 신청 현황 목록
    loadChildren: () => import('./pages/event-list/event-list.module').then(m => m.EventListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-event-detail', //회원:: 셀럽 섭외 신청 상세
    loadChildren: () => import('./pages/cel-event-detail/cel-event-detail.module').then(m => m.CelEventDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-event-check', //회원:: 셀럽 섭외 신청 전 확인
    loadChildren: () => import('./pages/cel-event-check/cel-event-check.module').then(m => m.CelEventCheckPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-approve', //셀럽 섭외 계약 동의
    loadChildren: () => import('./pages/user/user-approve/user-approve.module').then(m => m.UserApprovePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contract-doc', //셀럽 섭외 계약서 내용 생성
    loadChildren: () => import('./pages/contract-doc/contract-doc.module').then(m => m.ContractDocPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contract-doc-viewer', //셀럽 섭외 계약서 내용 수정
    loadChildren: () => import('./pages/contract-doc-viewer/contract-doc-viewer.module').then(m => m.ContractDocViewerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contract', //섭외 계약서 
    loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting-history', //회원:: 섭외 거래내역
    loadChildren: () => import('./pages/user/casting-history/casting-history.module').then(m => m.CastingHistoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'function-modal', //셀럽:: 모달:: 캐스팅 기능선택 도움말
    loadChildren: () => import('./pages/function-modal/function-modal.module').then(m => m.FunctionModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-info-modal', //셀럽:: 모달:: 프로필상세정보 수정
    loadChildren: () => import('./pages/celebrity/cel-info-modal/cel-info-modal.module').then(m => m.CelInfoModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event-detail-edit', //셀럽:: 섭외 수정
    loadChildren: () => import('./pages/celebrity/event-detail-edit/event-detail-edit.module').then(m => m.EventDetailEditPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting-detail', //셀럽:: 섭외 상세
    loadChildren: () => import('./pages/casting-detail/casting-detail.module').then(m => m.CastingDetailPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'detail-info-modal', //모달:: 섭외 계약 승인 동의화면에서 '캐스팅신청 전체정보' 버튼
    loadChildren: () => import('./pages/detail-info-modal/detail-info-modal.module').then(m => m.DetailInfoModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-cel-privacy-message', //셀럽:: 다이렉트메시지 거래내역 목록
    loadChildren: () => import('./pages/celebrity/dealrecord-cel-privacy-message/dealrecord-cel-privacy-message.module').then(m => m.DealrecordCelPrivacyMessagePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar-privacy-message', //더보기 > 캘린더 > 다이렉트메시지 캘린더
    loadChildren: () => import('./pages/calendar-privacy-message/calendar-privacy-message.module').then(m => m.CalendarPrivacyMessagePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar-casting', //더보기 > 캘린더 > 섭외 캘린더
    loadChildren: () => import('./pages/calendar-casting/calendar-casting.module').then(m => m.CalendarCastingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cel-info-confirm', //셀럽:: 캐스팅 프로필 상세정보
    loadChildren: () => import('./pages/celebrity/cel-info-confirm/cel-info-confirm.module').then(m => m.CelInfoConfirmPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'youtube-video-detail', //셀럽 캐스팅 프로필에서 유튜브 상세보기
    loadChildren: () => import('./pages/youtube-video-detail/youtube-video-detail.module').then(m => m.YoutubeVideoDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar-datetime-modal', //회원:: 모달:: 셀럽 섭외 신청 > 예약일시설정 > 날짜선택 
    loadChildren: () => import('./pages/calendar-datetime-modal/calendar-datetime-modal.module').then(m => m.CalendarDatetimeModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-modal', //결제 상세
    loadChildren: () => import('./pages/payment-modal/payment-modal.module').then(m => m.PaymentModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'casting-status', //모달:: 섭외 진행 현황
    loadChildren: () => import('./pages/casting-status/casting-status.module').then(m => m.CastingStatusPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-account', //회원:: 모달:: 셀럽 계정 선택 목록
    loadChildren: () => import('./pages/user/add-account/add-account.module').then(m => m.AddAccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-account-term', //회원:: 셀럽 추가 동의
    loadChildren: () => import('./pages/user/add-account-term/add-account-term.module').then(m => m.AddAccountTermPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-account-signup', //회원:: 셀럽 추가 일반 정보 입력
    loadChildren: () => import('./pages/user/add-account-signup/add-account-signup.module').then(m => m.AddAccountSignupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-account-celeb', //회원:: 셀럽 추가 셀럽 정보 입력
    loadChildren: () => import('./pages/user/add-account-celeb/add-account-celeb.module').then(m => m.AddAccountCelebPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-account-com', //회원:: 셀럽 추가 기업 정보 입력
    loadChildren: () => import('./pages/user/add-account-com/add-account-com.module').then(m => m.AddAccountComPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-celeb-account-com', //셀럽:: 모달:: 회원 계정으로 돌아가기 
    loadChildren: () => import('./pages/celebrity/add-celeb-account/add-celeb-account.module').then(m => m.AddCelebAccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth-celeb-manager', //셀럽 추가 메일 승인
    loadChildren: () => import('./pages/auth-celeb-manager/auth-celeb-manager.module').then(m => m.AuthCelebManagerPageModule),
  },
  {
    path: 'videomessage-user', //회원:: 영상메시지 신청 목록
    loadChildren: () => import('./pages/user/videomessage-user/videomessage-user.module').then(m => m.VideomessageUserPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'voicemessage-user', //회원:: 음성메시지 신청 목록
    loadChildren: () => import('./pages/user/voicemessage-user/voicemessage-user.module').then(m => m.VoicemessageUserPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'videocall-user', //회원:: 영상통화 신청 목록
    loadChildren: () => import('./pages/user/videocall-user/videocall-user.module').then(m => m.VideocallUserPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-status', //회원:: 모달:: 다이렉메시지 진행현황
    loadChildren: () => import('./pages/user/dealrecord-status/dealrecord-status.module').then(m => m.DealrecordStatusPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dealrecord-storage', //회원:: 다이렉트메시지 영상,음성 컨텐츠 상세
    loadChildren: () => import('./pages/user/dealrecord-storage/dealrecord-storage.module').then(m => m.DealrecordStoragePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor-tabs', //비회원:: 메인
    loadChildren: () => import('./pages/visitor/tabs/visitor-tabs.module').then(m => m.VisitorTabsPageModule),
  },
  {
    path: 'visitor-account', //비회원:: 모달:: 계정추가
    loadChildren: () => import('./pages/visitor/add-account/visitor-add-account.module').then(m => m.VisitorAddAccountPageModule),
  },
  {
    path: 'visitor-store', //비회원:: 모달:: 하트스토처
    loadChildren: () => import('./pages/visitor/store/visitor-store.module').then(m => m.VisitorStorePageModule),
  },
  {
    path: 'visitor-profile', //비회원:: 셀럽 프로필 상세
    loadChildren: () => import('./pages/visitor/profile-detail/visitor-profile-detail.module').then(m => m.VisitorProfileDetailPageModule),
  },
  {
    path: 'visitor-detail-my', //비회원:: 내 프로필
    loadChildren: () => import('./pages/visitor/visitor-detail-my/visitor-detail-my.module').then(m => m.VisitorDetailMyPageModule),
  },
  {
    path: 'visitor-casting',
    loadChildren: () => import('./pages/visitor/casting/visitor-casting.module').then(m => m.VisitorCastingPageModule),
  },
  {
    path: 'visitor-square-detail', //비회원:: 팬캠 상세
    loadChildren: () => import('./pages/visitor/square-detail/visitor-square-detail.module').then(m => m.VisitorSquareDetailPageModule),
  },
  {
    path: 'visitor-alarm', //비회원:: 알람
    loadChildren: () => import('./pages/visitor/alarm/visitor-alarm.module').then(m => m.VisitorAlarmPageModule),
  },
  {
    path: 'visitor-search', //비회원:: 셀럽 검색
    loadChildren: () => import('./pages/visitor/search/visitor-search.module').then(m => m.VisitorSearchPageModule),
  },
  {
    path: 'visitor-recomfriend', //비회원:: 추천 셀럽
    loadChildren: () => import('./pages/visitor/recomfriend/visitor-recomfriend.module').then(m => m.VisitorRecomfriendPageModule),
  },
  {
    path: 'visitor-friend-category', //비회원:: 카테고리별 셀럽
    loadChildren: () => import('./pages/visitor/friend-category/visitor-friend-category.module').then(m => m.VisitorFriendCategoryPageModule),
  },
  {
    path: 'visitor-casting-list', //비회원:: 섭외 신청 목록
    loadChildren: () => import('./pages/visitor/casting-list/visitor-casting-list.module').then(m => m.VisitorCastingListPageModule),
  },
  {
    path: 'visitor-message-list', //비회원:: 다이렉트메시지 신청 목록
    loadChildren: () => import('./pages/visitor/message-list/visitor-message-list.module').then(m => m.VisitorMessageListPageModule),
  },
  {
    path: 'visitor-friendtalk-ask', //비회원:: 채팅 신청
    loadChildren: () => import('./pages/visitor/friendtalk-ask/visitor-friendtalk-ask.module').then(m => m.VisitorFriendtalkAskPageModule),
  },
  {
    path: 'private-voice', //회원:: 주문형메시지 - 음성
    loadChildren: () => import('./pages/private-voice/private-voice.module').then(m => m.PrivateVoicePageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'private-video', //회원:: 주문형메시지 - 영상
    loadChildren: () => import('./pages/private-video/private-video.module').then(m => m.PrivateVideoPageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'private-video2', //회원:: 주문형메시지 - 영상 (ejel)
    loadChildren: () => import('./pages/private-video2/private-video2.module').then(m => m.PrivateVideo2PageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'character-chat', //회원:: 캐릭터 챗
    loadChildren: () => import('./pages/character-chat/character-chat.module').then(m => m.CharacterChatPageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'main',
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

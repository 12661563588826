import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { first } from 'rxjs/operators';
import { HeartUsage, Purchase, heartCommentReport } from '../models/heart.model';
import { User } from '../models/user.model';
import { CommonService } from './common.service';
import { DbService } from './db.service';
import { environment } from 'src/environments/environment';
import { ActionSheetController } from '@ionic/angular';
import { LoadingService } from './load.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class HeartService {
  heartUsage;
  constructor(
    private db: DbService, 
    private common: CommonService, 
    public http: HTTP,
    private actionSheetController: ActionSheetController,
    private loading: LoadingService,
    private alert: AlertService,
  ) {}

  /**
   * 하트 사용!
   * @param userId 사용자 userId
   * @param userType 사용자 타입 (user / celeb / company)
   * @param msgType 사용 message type
   * @param usedHeart 사용한 하트 개수
   */
  public async useHeart(userId: string, celebId: string, userType: string, msgType: string, usedHeart: number): Promise<void | string> {
    let heartUsage: HeartUsage = {
      id: this.common.generateFilename30(),
      dateCreated: new Date().toISOString(),
      msgType: msgType,
      type: '',
      subject: '',
      target: '',
      runTime: 0,
      resvDate: '',
      resvTime: '',
      msgTime: '',
      content: '',
      usedHeart: usedHeart,
      cancelSwitch: false,
      refund: 0,
      cancelType: '',
      cancelDate: '',
      badgeSwitch: true,
      isSent: false,
      serviceFailed: false,
      serviceDone: false,
      excutedDate: '',
      userId: userId,
      userType: userType,
      celebId: celebId,
    };

    if (msgType == 'addFriend' || msgType == 'voice' || msgType == 'video' || msgType == 'call') {
      const celeb = await this.db.doc$(`user/${celebId}`).pipe(first()).toPromise();
      if (celeb) {
        heartUsage.celebType = celeb.companyType == 'celebCompany' ? 'celebCompany' : 'celeb';
      }
    }

    await this.addUserHeart(userId, usedHeart, false);
    await this.updateHeartUsage(heartUsage);
    // return 'success';
    return heartUsage.id;
  }

  /**
   * store에서 하트 충전!
   * @param userId 사용자 userId
   * @param userType 사용자 타입 (user / celeb / company)
   * @param price 하트 충전시 지불 한 가격
   * @param charged 하트 충전 개수
   * @returns
   */
  public async addHeart(userId: string, userType: string, price: number, charged: number): Promise<void | string> {
    const heart: Purchase = {
      id: this.common.generateFilename30(),
      dateCreated: new Date().toISOString(),
      charged: charged,
      price: price,
      userId: userId,
      userType: userType,
      type: 'purchase',
    };

    await this.addUserHeart(userId, charged, true);
    await this.updatePurchase(heart);
    return 'success';
  }

  /**
   * 사용내역 리스트 저장
   * @param heartUsage 사용내역 (heartUsage 상세내역)
   */
  private async updateHeartUsage(heartUsage: HeartUsage): Promise<void | boolean> {
    await this.db.updateAt(`heartUsage/${heartUsage.id}`, heartUsage);
    return true;
  }

  /**
   * 충전내역 리스트 저장
   *
   * @param heart 충전내역 (purchase 상세내역)
   * @returns
   */
  private async updatePurchase(heart: Purchase): Promise<void | boolean> {
    await this.db.updateAt(`purchase/${heart.id}`, heart);
    return true;
  }

  /**
   * 사용자 하트수 업데이트
   * @param userId 사용자 userId
   * @param charged 하트 수
   * @param addSwitch 사용인지 충전인지! (충전 => true, 사용 => false)
   * @returns
   */
  public async addUserHeart(userId: string, charged: number, addSwitch: boolean): Promise<void | boolean> {
    const user: User = await this.db.doc$(`user/${userId}`).pipe(first()).toPromise();

    // user 정보 없을 경우엔 함수 종료
    if (!user) {
      return false;
    }

    // 혹시 예외처리로 NaN가 떠 있을 경우
    if (isNaN(user.heart)) {
      user.heart = 0;
    }

    const alreadyHeart = user.heart;
    let sumHeart = alreadyHeart + charged;

    // 하트를 빼야 하는 경우 (사용했을 경우!)
    // 기본은 충전!
    if (!addSwitch) {
      sumHeart = alreadyHeart - charged;
    }

    this.db.updateAt(`user/${userId}`, {
      heart: sumHeart,
    });
  }

  /**
   *
   * @param heartUsageId heartUsageId
   * @returns
   * 셀럽이 프라이빗 메시지를 보내기 전에
   * 프라이빗 메시지를 취소하였는지 체크하기 위한 function
   *
   * return true: 취소하지 않았음 (가능)
   * return false: 취소! (불가능)
   */
  public async checkHeartUsageCancelByUser(heartUsageId: string): Promise<boolean> {
    const heartUsage: HeartUsage = await this.db.doc$(`heartUsage/${heartUsageId}`).pipe(first()).toPromise();

    if (heartUsage.cancelSwitch) {
      return false;
    }

    return true;
  }

  /**
   *
   * @param heartUsageId heartUsageId
   *
   * 10일 뒤에 취소도 하지않고, 셀럽이 응답해주지 않았을 경우 자동 환불 process
   */

  // 이거 created로바꿔서 사용 안해요
  public async autoRefundHeart(heartUsageId: string): Promise<void | boolean> {
    this.http.post(`${environment.firebasefFunc.autoRefundProcess}?heartUsageId=${heartUsageId}`, {}, {}).then(
      data => {},
      error => {}
    );
  }

  /**
   * 댓글 수정/삭제 or 신고/차단
   */
  public async replyMore(reply): Promise<void | boolean> {
    const userId: string = localStorage.getItem('userId');
    const writtenUserId: string = reply.userId.uid;

    // 작성자가 본인일 경우엔 (수정 or 삭제)
    if (userId == writtenUserId) {
      this.replyMy(reply);
      return false;
    }

    this.replyPartner(reply);
  }

  //수정/삭제
  private async replyMy(reply): Promise<void | boolean> {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-yr',
      buttons: [
        {
          text: '수정',
          handler: async () => {
            actionSheet.dismiss();
            const data = await this.alert.cancelOkTextareaBtn('취소', '수정하기', '댓글 수정하기', reply.content);
            if (!data.type) {
              return false;
            }

            if (!data.content) {
              this.alert.okBtn('댓글을 입력해주세요.');
              return false;
            }

            await this.db.updateAt(`heartComment/${reply.id}`, {
              content: data.content,
            });
          },
        },
        {
          text: '삭제',
          handler: async () => {
            actionSheet.dismiss();
            await this.db.delete(`heartComment/${reply.id}`);
            this.alert.toast('댓글을 삭제하였습니다.');
          },
        },

        {
          text: '취소',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }

  private async replyPartner(reply): Promise<void | boolean> {
    const myUid: string = localStorage.getItem('userId');
    const writtenUserId: string = reply.userId.uid;

    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-yr',
      buttons: [
        {
          text: '신고하기',
          handler: async () => {
            actionSheet.dismiss();
            const data = await this.alert.cancelOkTextareaBtn('취소', '신고하기', '댓글 신고하기', '신고하는 이유를 작성해 주세요.');
            if (!data.type) {
              return false;
            }

            if (!data.content) {
              this.alert.okBtn('신고 이유를 작성해주세요.');
              return false;
            }

            const report: heartCommentReport = {
              userId: myUid,
              dateCreated: new Date().toISOString(),
              postId: reply.id,
              reason: data.content,
              id: this.common.generateFilename30(),
              type: 'report',
            };

            this.loading.present();
            await this.db.updateAt(`commentHeartReport/${report.id}`, report);

            this.loading.dismiss();
            this.alert.toast('댓글을 신고하였습니다.');
          },
        },

        {
          text: '취소',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }
}
